<template>
  <v-card class="grey lighten-4 pa-5 mt-3">
    <v-row dense>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          hide-details
          v-model="contact.name"
          label="Nome*"
          placeholder="Nome completo"
          :rules="[() => !!contact.name || 'Este campo é obrigatório']"
          dense
          rounded
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          hide-details
          v-model="contact.phone_number"
          label="Telefone*"
          v-mask="['(##) ####-####', '(##) #####-####']"
          placeholder="(xx) x.xxxx-xxxx"
          :rules="[() => !!contact.phone_number || 'Este campo é obrigatório']"
          dense
          rounded
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          hide-details
          v-model="contact.cel_number"
          label="Celular*"
          v-mask="['(##) ####-####', '(##) #####-####']"
          placeholder="(xx) x.xxxx-xxxx"
          dense
          rounded
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          hide-details
          v-model="contact.department"
          label="Departamento"
          placeholder="Departamento"
          dense
          rounded
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          hide-details
          v-model="contact.email"
          label="Email*"
          type="email"
          :rules="[(v) => !!v || 'Campo obrigatório', rules.email]"
          placeholder="exemplo@gmail.com"
          outlined
          rounded
          dense
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          hide-details
          v-model="contact.password"
          @click:append="showPassword = !showPassword"
          :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
          type="text"
          outlined
          label="Senha"
          rounded
          dense
        />
      </v-col>
    </v-row>
    <v-row
      align="center"
      dense
    >
      <v-col
        cols="12"
        md="6"
      >
        <v-chip
          v-show="contact.status != null"
          small
          :color="contact.status ? 'success' : 'error'"
        >
          {{ contact.status ? 'Ativo' : 'Inativo' }}
        </v-chip>
      </v-col>
      <v-col
        align="right"
        cols="12"
        md="6"
      >
        <v-btn
          v-if="contact.hasBudget === true"
          @click="$emit('changeStatus', contact)"
          rounded
          outlined
          :color="contact.status ? 'error' : 'primary'"
          class="mr-2"
        >
          {{ contact.status ? 'Desativar contato' : 'Ativar contato' }}
        </v-btn>
        <v-btn
          v-else
          @click="$emit('remove', contact)"
          rounded
          color="error"
        >
          Excluir Contato
          <v-icon right>fas fa-trash</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    contact: Object,
    contact_idx: Number,
  },
  data: () => ({
    showPassword: false,
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Insira um email válido'
      },
    },
  }),
}
</script>